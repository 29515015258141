exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobb-tsx": () => import("./../../../src/pages/jobb.tsx" /* webpackChunkName: "component---src-pages-jobb-tsx" */),
  "component---src-pages-kurs-tsx": () => import("./../../../src/pages/kurs.tsx" /* webpackChunkName: "component---src-pages-kurs-tsx" */),
  "component---src-pages-ny-stillingsannonse-tsx": () => import("./../../../src/pages/ny-stillingsannonse.tsx" /* webpackChunkName: "component---src-pages-ny-stillingsannonse-tsx" */),
  "component---src-pages-sok-tsx": () => import("./../../../src/pages/sok.tsx" /* webpackChunkName: "component---src-pages-sok-tsx" */),
  "component---src-templates-ad-tsx": () => import("./../../../src/templates/ad.tsx" /* webpackChunkName: "component---src-templates-ad-tsx" */),
  "component---src-templates-article-archive-tsx": () => import("./../../../src/templates/articleArchive.tsx" /* webpackChunkName: "component---src-templates-article-archive-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-document-tsx": () => import("./../../../src/templates/document.tsx" /* webpackChunkName: "component---src-templates-document-tsx" */)
}

